import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {ANALYTICS_BODY, qbdSetupConstants, qboSetupConstants} from '../../helpers/constants';
import QBOButton from "../../assets/images/connect-to-quickbooks.svg";
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import {isMacintosh} from "../../helpers/device";

class Home extends React.Component {
    state = {
        mode: '',
        open: false
    }

    interval = null;

    handleOAuth = (mode) => {
        if (mode === 'Online') {
            this.syncQBO()
        }
        if (mode === 'Desktop') {
            this.syncQBD()
        }
        this.listenForOauth();
    };

    componentDidMount() {
        const {handshake, integrationProgress, businessId} = this.props;
        const {identity: {poyntId}} = integrationProgressHelper(integrationProgress);
        if (businessId && !poyntId) {
            handshake({businessId});
        }
    }

    syncQBO = () => {
        const {updateAnalytics, businessId, getQBOAuth, identify} = this.props;
        identify({businessId})
        updateAnalytics({
            ...ANALYTICS_BODY.start_qbo_accounting_sync,
            id: businessId,
        })
        getQBOAuth({businessId}).then(() => {
            this.openOauthWindow();
        })
    }

    syncQBD = () => {
        const {updateAnalytics, identify, businessId, postPartialIntegrationProgress, getIntegrationProgress, resetChartAccount, resetSelectedChartAccount} = this.props;
        resetChartAccount();
        resetSelectedChartAccount();
        identify({businessId});
        updateAnalytics({
            ...ANALYTICS_BODY.start_qbd_accounting_sync,
            id: businessId,
        })
        postPartialIntegrationProgress({
            businessId,
            identity: {
                destinationName: 'qbd',
            },
            progress: integrationProgressConstants.qbd_setup_0
        }).then(() => {
            getIntegrationProgress({
                businessId
            })
        })
    }

    listenFunction = (event) => {
        if (event.data === 'oauth-done') {
            const {businessId, getIntegrationProgress} = this.props;
            getIntegrationProgress({
                businessId
            })
            window.removeEventListener('message', this.listenFunction, true);
        }
    }

    listenForOauth = () => {
        this.interval = setInterval(() => {
            const {businessId, getIntegrationProgress} = this.props;
            getIntegrationProgress({
                businessId
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    openOauthWindow() {
        const {oauthURL: {authUrl}} = this.props;
        window.addEventListener('message', this.listenFunction, true);
        window.open(authUrl, "externalWindow", "width=900,height=700,left=200,top=200");
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <main className={classes.content}>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Grid container spacing={0}>
                                <Grid item xs={2} className={clsx(classes.centeredImageContent, classes.borderRight)}>
                                    <div className={classes.companyImage}>
                                        <img className={classes.imageContain} src={qbdSetupConstants.intuitLogo}
                                             alt="Intuit logo"/>
                                    </div>
                                </Grid>
                                <Grid item xs={8} className={classes.integrationInfo}>
                                    <Box className={classes.companyImage}>
                                        <img className={classes.imageContain} src={qbdSetupConstants.screen}
                                             alt="Intuit logo"/>
                                    </Box>
                                    <Box className={classes.integrationInfoContent}>
                                        <Typography variant="h6">{qbdSetupConstants.title}</Typography>
                                        <Typography variant="body2">{qbdSetupConstants.description}</Typography>
                                        {isMacintosh() &&
                                        <Box mt={3}>
                                            <Typography variant="subtitle2">
                                                Please use a Windows PC to setup QuickBooks Desktop integration.
                                            </Typography>
                                        </Box>}
                                    </Box>
                                </Grid>
                                <Grid item xs={2} className={classes.centeredImageContent}>
                                    <Button
                                        className={classes.buttonMinWidth}
                                        id="setup"
                                        variant="contained"
                                        disableElevation
                                        disableFocusRipple
                                        color="grey"
                                        // disabled
                                        // onClick={() => this.handleOAuth('Desktop')}
                                    >
                                        Sync
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Grid container spacing={0}>
                                <Grid item xs={2} className={clsx(classes.centeredImageContent, classes.borderRight)}>
                                    <div className={classes.companyImage}>
                                        <img className={classes.imageContain} src={qboSetupConstants.intuitLogo}
                                             alt="Intuit logo"/>
                                    </div>
                                </Grid>
                                <Grid item xs={8} className={classes.integrationInfo}>
                                    <Box className={classes.companyImage}>
                                        <img className={classes.imageContain} src={qboSetupConstants.screen}
                                             alt="Intuit logo"/>
                                    </Box>
                                    <Box className={classes.integrationInfoContent}>
                                        <Typography variant="h6">{qboSetupConstants.title}</Typography>
                                        <Typography variant="body2">{qboSetupConstants.description}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} className={classes.centeredImageContent}>
                                    <img
                                        alt="qbo button"
                                        src={QBOButton}
                                        className={classes.imageButton}
                                        // onClick={() => this.handleOAuth('Online')}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </main>
            </div>
        );
    }
}

export default Home;
