export default () => ({
    success: {
        color: '#fff !important',
        backgroundColor: '#39DA91 !important',
        fontWeight: 700,
        '& svg': {
            color: '#fff',
        }
    },
    error: {
        color: '#fff !important',
        backgroundColor: '#E74C3C !important',
        fontWeight: 700,
        '& svg': {
            color: '#fff',
        }
    },
    banner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#e8eefc',
        padding: '12px 16px',
        borderRadius: '8px',
        marginBottom: '16px',
        fontFamily: 'Arial, sans-serif',
        color: '#1d3b8b',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
    },
    bannerText: {
        flex: 1,
    },
    bannerTitle: {
        margin: 0,
        fontSize: '18px',
        fontWeight: 'bold',
    },
    bannerDescription: {
        margin: '4px 0 0',
        fontSize: '14px',
        color: '#4a5f9c',
    },
    bannerButton: {
        display: 'inline-block',
        background: 'none',
        border: '1px solid #1d3b8b',
        padding: '8px 12px',
        fontSize: '13px',
        color: '#1d3b8b',
        textDecoration: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            background: '#1d3b8b',
            color: 'white',
        },
    },
    bannerLink: {
        textDecoration: 'underline',
        transition: 'all 0.3s ease',
    },
});