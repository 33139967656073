export const allowedBusinessIds = [
    "ff852e1b-aec8-46a8-9493-1d75163dad9d",
    "08662578-b6ae-4df7-bd81-13b6969c1254",
    "f490e2a7-44c5-47b6-9a49-aa77a5b2909e",
    "a1ec24da-761c-432c-b406-7f3b4d1903f5",
    "f9c791e0-6e2c-4c47-8948-463bb0fa3db5",
    "5502d404-b6b2-4c64-918e-935139516fa3",
    "874cb035-57b3-416e-93d4-b9cf812faa91",
    "cfa7592b-e348-45c7-849d-9bbd1e297a8b",
    "fb032035-f39f-4a65-bd4f-433f136ee205",
    "42793b98-e530-4513-bd1e-235c510a44b3",
    "eedb2c15-ece5-4d5b-8fd8-eda6a58f8563",
    "41a40a7b-92fc-40bd-a996-855e3a083833",
    "8dd93043-3d5e-411d-87fb-ab2982cc0597",
    "695b68ae-b17e-4203-9243-ccf1a15fc091",
    "82b04cb9-2e9b-46ae-85ac-bd6a635b9f6f",
    "b0a0e8f1-56c6-4c54-a5c4-6a46e16129c7",
    "c6c3a096-ae9c-4af0-bf42-c5e82447f272",
    "a9d6a209-2afa-4fc5-8650-ff26175902d6",
    "99ffb7d5-ccb2-4916-bea4-f68d279d890c",
    "87907c3e-d403-4765-ae4c-82e9b028ff1b",
    "be461c6a-8beb-4790-9b0c-ee3ad9cd3347",
    "ed5b6d31-56c7-4022-8b32-ab546cd71703",
    "e28c4a22-e401-4579-92c9-625e20ad924c",
    "3aa8c56f-bde2-4c7f-bed7-f40bef44e5c2",
    "76a5de6f-7072-49a9-b47e-80772872d382",
    "ea569014-9b41-48e9-a9a4-fd184bbe31e8",
    "f9525b77-7c65-4ae3-a4e3-ec36990f81de",
    "538eca89-31c8-4b90-beab-22d5ad06c4bf",
    "b7d7b0af-fef6-46e9-90cb-426f8f941fdd",
    "0a5aff19-231c-4e18-9394-21694cc4e30e",
    "d7cea45e-31c3-4c45-b150-4bb6baf90c5d",
    "f42103a9-2f11-4e43-8a41-df1195a85cb6",
    "9a224717-bd2c-45b5-a884-0028ff034ce0",
    "72da189d-929d-433f-82d8-31d402bc78ba",
    "c7dc7b89-95e7-4da1-9444-a1a12c135fe3",
    "a374406c-4eb2-4edd-9a14-68aad34b7c78",
    "5a0ed9c0-3672-4f09-ac20-940186614dd6",
    "fd94f7dc-17ac-45f6-a2c8-c92fd9c4bf40",
    "718bf2fb-3c7b-43e7-8823-3ceffb1f4847",
    "8e6c430b-97d3-4d4b-ac7d-e2297e2e385b",
    "48143633-4812-4051-af08-9a7e2439d44b",
    "a739567a-7ceb-4c6a-b40c-821bf54ea8e1",
    "16c0c606-c113-47a0-a2a9-6621362049c0",
    "1326578c-c847-4313-9e8c-2f1404488e9b",
    "4ea56aa2-5aac-4577-8f32-c2159564e2cb",
    "e0a4bd57-e337-40b0-92ef-08cbc367f7a4",
    "0ec3d200-d0f9-4c99-9837-8ff878d87c99",
    "78382014-5b59-41cf-a370-50de270cbeb4",
    "48898b0d-0bf4-4da9-bef1-7ea56fbe9017",
    "9ed6a510-ec39-494e-991a-c383a9899ec8",
    "d7e4a603-88c4-4c22-973f-b2e7cde1270d",
    "711e9387-da47-4578-868b-199346ffbbc8",
    "f0aa95a6-d2f3-4591-a25f-6a762f58c68d",
    "c73a8391-08db-4230-bff8-4e99995816b6",
    "f09376a8-259b-4035-b430-e5cf287d463a",
    "0cab1494-6fe5-47a0-b697-b0e349b4ca9c",
    "23826e06-5aa5-4c08-b0bd-950c8ba3488f",
    "a0ebb271-4709-40b2-b6da-c741f8ab4530",
    "d065c699-5440-4676-991d-7e5fbf3370e5",
    "2dfeb99f-d41b-4388-950e-6b044def1cdb",
    "aa5679e4-435b-4715-bff3-1b59a678a2f7",
    "c5c66ac4-a2ab-4bca-9e66-f6763acab1fa",
    "a85b08ef-e6e8-4d91-bed8-fe50376c0cd2",
    "e3e2477a-c439-483f-b317-4c5a5422deb0",
    "f036bc82-3df1-4a8d-b677-d9ae19bdfab3",
    "644c8351-6bed-4b65-a473-19de2441394a",
    "66deb47f-92b3-43eb-b9ab-720b3b150ed3",
    "be7f622f-c46c-4428-b592-4e8394573649",
    "fc8a5b9f-752a-4ded-9423-4edc64776c22",
    "4c46b13c-7eec-4a0c-85e5-dcabbc9120ed",
    "8b4fc5a2-034d-41a8-8368-ca36553c5b8d",
    "8754f801-ec9a-4a86-b9c0-e8bcd551cbeb",
    "df457cb3-b3d5-4910-9493-9164f752895c",
    "2a2a1bfc-a93f-4af3-8dd9-8076c928191d",
    "738adba9-dd21-4de0-a50e-fda45c2e97cc",
    "30b52937-7f46-45ce-ba14-3b95f6a2c91f",
    "9d2a6422-f695-4af6-b7e9-27addd9bb5df",
    "ccd93f11-b539-4611-ba06-19fbfbbc7eb4",
    "23eeb500-d509-4609-b3a1-57d260c73878",
    "c674dce8-923f-49a0-9cd1-0e1cfcbb5e31",
    "c66edaec-da1a-4502-b1e8-7ea3e9db034e",
    "3cab968e-6dd6-4c0b-b3e6-d852175e413f",
    "221bd0c3-9fb0-4d28-a8d9-8a3a716872d4",
    "29d0aa46-388c-4e6a-862c-730650f63573",
    "de88691f-eda4-46c7-a168-d241514798f4",
    "fc694562-b4bb-4e9e-80dd-420ac4e686e6",
    "4be1d815-75f8-438a-a27a-9e472cdad378",
    "550ddaab-5ea2-45c6-a68b-bcfbb78c87a7",
    "8fc32b24-da2c-4c25-a977-8702169e40e5",
    "fca8a604-b09d-45af-931c-06d21be6ec94",
    "68105c07-2a68-4f7e-b97f-74ecd3a508b6",
    "498818a8-e26a-415f-bc14-16639911427b",
    "2572484e-0f14-49a7-a450-72780ab61c85",
    "22db80c6-2b45-4180-8961-b0edfeb463b9",
    "e4d0f076-396c-44ab-8c28-871118dc5711",
    "38e690d2-3d0f-4a74-8654-25d2d96bab48",
    "0a89c48a-4e03-4670-9976-7191415c649c",
    "6d518cf0-cf47-4299-aa00-b33d0733628a",
    "3293bed4-eb08-49f4-93bd-a7ea5c0ca64f",
    "7ff0b544-20f7-4201-9b96-5e655735f980",
    "cd2ee171-0445-4d44-91af-e21c892f98e9",
    "2d8c938b-4b54-4a79-b6bf-98b6f32f52c6",
    "497f9f4e-f034-437c-8e21-252f93cdd3a3",
    "1df716da-fd21-40d5-971e-430ae7ee01d0",
    "9e8aa642-b07e-429d-a4bf-c0e77a23625c",
    "14d7ca0b-c148-4ecf-9bd0-302a19a4fc2d",
    "11ea1d7a-0fe1-4008-8e6d-1a1077b087d8",
    "e27532d1-d7d2-47af-aec7-49886472c8b6",
    "a0aad28d-b05e-4102-9776-5bd2305f3ed6",
    "6897d851-6e48-4fb5-b982-dfde27c9e7d8",
    "18522f54-28ca-4faa-9799-1e10aaa90688",
    "70cb43ff-3fd0-419f-a203-910c2ff3656d",
    "9bb66b65-0e76-4c7d-afd8-3a00c0e07ab4",
    "950d6a71-e9d6-4127-a723-83fc67e4a46f",
    "db6fa28b-091c-4d7e-992d-5d234fe450fb",
    "864df928-421f-40cf-b61b-cde4cde50b42",
    "2ef6ef98-18cd-4d45-8f4e-946b31b5df3c",
    "36089440-b0aa-4066-838b-0d32981cab50",
    "e25fd949-ea09-4114-b5f2-a72d496a9c0e",
    "f4dbd41c-aa85-41c9-bea1-4a52ea9d5ff0",
    "27c4c5ef-cde3-402f-aa1b-a397c31bfbc8",
    "1bf713bb-0a3f-41fd-89fc-7fc97ad131c9",
    "5188367d-7014-4bb4-b335-b8972d1c994c",
    "4a6a2fc9-2c77-43c1-b60e-2bbb4571e1e6",
    "39de8ea9-495a-4828-baad-da1a109b9f1a",
    "fc806a27-d389-42df-9bba-a94e0565d2de",
    "4b49cdb9-213c-4491-906a-d15fbde22170",
    "afda782f-bbd3-4b48-9c97-c8e369143157",
    "c50921e6-ab8e-4c36-a855-aec381f3c811",
    "ff9ee75a-fff2-4f6f-9711-d8ae0215467e",
    "da751d23-52db-4701-a747-4c896e388d9a",
    "0b60f73c-41cb-4bb3-998e-543cc2f6c4e2",
    "ebeacc68-bff6-4170-8a41-a9e5b9e5f2f4",
    "92c70fee-76f1-4ec9-b68d-dfcb96cc0722",
    "124930fc-761c-415b-86c1-0099eb1b08c5",
    "44717a95-6317-4ff6-9984-2c1d444cdb6d",
    "50a479ea-2826-48e2-aae0-0b8f14fdad4c",
    "2c776ff7-6938-48b5-a264-880a61f76e0f",
    "15f58c94-0872-49b7-b8cb-4f937faf44c7",
    "d407aa60-b655-4c9a-98a2-c1274a741f83",
    "fa78b742-3871-457a-a3cf-c9e8699a2707",
    "ac1217a3-8b7a-45a5-8637-395d27fc6293",
    "fa1d9304-20dc-4d9c-8a27-823be0389420",
    "429302f6-6f1d-4b21-9c20-6e5a3886e2a3",
    "f75b4be9-9455-4b2d-8801-f948491d3fea",
    "fdb4ab8c-489b-4088-9905-332e8a4bfc7a",
    "a1a865cf-843f-449f-99d4-e695996f3d6d",
    "e83cfecc-4d30-4145-8f88-c304284bf227",
    "2fb5e0a1-4744-431d-857a-e8658ce22307",
    "daeeb977-b286-4dbc-b2b3-e5d602699bdf",
    "a1e78912-d092-4d2c-914c-d0c435c6edba",
    "2c6e58cd-6ddc-45c5-a76a-2c36d53fb2a4",
    "b2afefd2-9c82-4045-8f0e-8c3c0b9a73ee",
];